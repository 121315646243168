import CustomerCareKeys from '../../../../../../locales/keys';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {BILLING_EFFECTIVE_DATE_ORDER_OPTIONS,
    REASON_CODES,
    SCHEDULED_ORDER_OPTIONS} from '../../../../../../../src/customercare.constants';
import moment from 'moment';
import clone from 'ramda/src/clone';

class FutureDatedOrdersController {
    constructor($timeout) {
        Object.assign(this, {
            $timeout,
            BILLING_EFFECTIVE_DATE_ORDER_OPTIONS,
            billingEffectiveDefaultDate: moment().startOf('day').toDate(),
            billingEffectiveMaximumDate: null,
            billingEffectiveMinimumDate: null,
            BUYERS_REMORSE: REASON_CODES.BUYERS_REMORSE,
            CustomerCareKeys,
            defaultExecutionOptions: {
                effective: `${SCHEDULED_ORDER_OPTIONS.EFFECTIVE_IMMEDIATELY}`
            },
            executionOptionsModel: {},
            filteredBillingEffectiveDateOptions: [],
            isBillingEffectiveDateInvalid: false,
            isFutureDatedOrderDateInvalid: false,
            MAXIMUM_COMMENT_LENGTH: 500,
            onBedDateChange: this.onBedDateChange.bind(this),
            onSpecifiedDateChange: this.onSpecifiedDateChange.bind(this),
            SCHEDULED_ORDER_OPTIONS
        });

    }

    $onInit() {
        this.executionOptionsModel = this.executionOptionsSavedCopy && this.executionOptionsSavedCopy.effective ?
            clone(this.executionOptionsSavedCopy) : this.defaultExecutionOptions;

        if (!this.initializeDatesToNull) {
            if (this.orderAllowedForToday) {
                this.executionOptionsModel.specifiedDateFromPicker = this.executionOptionsSavedCopy && this.executionOptionsSavedCopy.specifiedDate || this.minDate;
            } else {
                this.executionOptionsModel.specifiedDate = this.executionOptionsSavedCopy && this.executionOptionsSavedCopy.specifiedDate || this.getExecutionDate();
            }
        }

        if (!this.showImmediateOption && this.showBillCycleOption && !this.executionOptionsSavedCopy.effective) {
            this.executionOptionsModel.effective = `${SCHEDULED_ORDER_OPTIONS.ON_NEXT_BILL_CYCLE}`;
        }

        if (!this.showImmediateOption && !this.showBillCycleOption && this.showDatePickerOption) {
            this.executionOptionsModel.effective = `${SCHEDULED_ORDER_OPTIONS.ON_SPECIFIED_DATE}`;
        }

        this.executionOptionsModel.applyBalanceToInvoice = true;
        this.onExecutionOptionChange()(this.executionOptionsModel);

        if (this.isBillingEffectiveDateShown && this.billingEffectiveDateOptions && this.billingEffectiveDateOptions.length) {
            this.filterAndFormatBillingEffectiveDateOptions(true);
        }
    }

    $onChanges(changesObj) {
        if (this.isBillingEffectiveDateShown && this.executionOptionsModel.effective && changesObj.billingEffectiveDateOptions && changesObj.billingEffectiveDateOptions.currentValue.length) {
            this.filterAndFormatBillingEffectiveDateOptions(true);
        }

        if (!this.showBillCycleOption && this.executionOptionsModel && this.executionOptionsModel.effective === `${SCHEDULED_ORDER_OPTIONS.ON_NEXT_BILL_CYCLE}`) {
            this.executionOptionsModel.specifiedDate = null;
        }

        if (this.executionOptionsModel && this.executionOptionsModel.disconnectReason) {
            this.onExecutionOptionChange()(this.executionOptionsModel);
        }

        if (changesObj.minDate) {
            this.minDateObj = moment(changesObj.minDate.currentValue);
        }
        if (changesObj.maxDate) {
            this.maxDateObj = moment(changesObj.maxDate.currentValue);
        }
    }

    getBillingEffectiveMaxDate() {
        switch (convertStringToNumber(this.executionOptionsModel.effective)) {
            case SCHEDULED_ORDER_OPTIONS.EFFECTIVE_IMMEDIATELY:
                return moment();
            case SCHEDULED_ORDER_OPTIONS.ON_NEXT_BILL_CYCLE:
                return moment(this.billCycleDate);
            case SCHEDULED_ORDER_OPTIONS.ON_SPECIFIED_DATE:
                const maxDate = this.executionOptionsModel.specifiedDateFromPicker || this.executionOptionsModel.specifiedDate || this.defaultExecutionOptions.specifiedDate;
                return maxDate ? moment(maxDate) : null;
            default:
                return null;
        }
    }

    setBillingEffectiveDateAndRange() {
        const momentMinimumNonFutureDatedOrderDate = moment(this.billingEffectiveDateSettings.minimumDate).add(1, 'hours');

        if (this.billingEffectiveDateSettings.pastDateMaxLength && convertStringToNumber(this.executionOptionsModel.effective) !== SCHEDULED_ORDER_OPTIONS.EFFECTIVE_IMMEDIATELY) {
            // Subtract the configured amount, and take the largest of the 2 possible min dates.
            const momentMinimumFutureDatedOrderDate = moment(this.getExecutionDate()).subtract(this.billingEffectiveDateSettings.pastDateMaxLength, 'days');

            this.billingEffectiveMinimumDate = moment(momentMinimumFutureDatedOrderDate).isAfter(momentMinimumNonFutureDatedOrderDate) ?
                momentMinimumFutureDatedOrderDate :
                momentMinimumNonFutureDatedOrderDate;
        } else {
            this.billingEffectiveMinimumDate = momentMinimumNonFutureDatedOrderDate;
        }

        this.billingEffectiveMaximumDate = this.getBillingEffectiveMaxDate();
    }

    filterAndFormatBillingEffectiveDateOptions(isQuoteSkipped = false) {
        const filteredBillingEffectiveDateOptions = {};

        const savedBillingEffectiveIntention = convertStringToNumber(this.executionOptionsModel.billingEffectiveIntention);

        this.billingEffectiveDateOptions.forEach((option) => {
            if (option.Valid) {
                const isDisabled = option.OrderExecutionDateIntention !== convertStringToNumber(this.executionOptionsModel.effective);
                const isShown = !isDisabled || !filteredBillingEffectiveDateOptions[option.BillingEffectiveDateIntention];
                const isSelected = !isDisabled && savedBillingEffectiveIntention === option.BillingEffectiveDateIntention;

                if (isShown) {
                    filteredBillingEffectiveDateOptions[option.BillingEffectiveDateIntention] = Object.assign({}, option, {
                        isDisabled,
                        isSelected
                    });
                }

                if (option.Default) {
                    this.executionOptionsModel.billingEffectiveIntention = `${option.BillingEffectiveDateIntention}`;
                }
            }
        });

        if (filteredBillingEffectiveDateOptions[savedBillingEffectiveIntention] && !filteredBillingEffectiveDateOptions[savedBillingEffectiveIntention].isDisabled) {
            this.executionOptionsModel.billingEffectiveIntention = `${savedBillingEffectiveIntention}`;
        }

        this.filteredBillingEffectiveDateOptions = Object.values(filteredBillingEffectiveDateOptions);
        this.setBillingEffectiveDateAndRange();

        this.updateExecutionOptions(false, isQuoteSkipped);
    }

    getExecutionDate() {
        switch (this.executionOptionsModel.effective) {
            case `${SCHEDULED_ORDER_OPTIONS.EFFECTIVE_IMMEDIATELY}`:
                return null;
            case `${SCHEDULED_ORDER_OPTIONS.ON_NEXT_BILL_CYCLE}`:
                return moment(this.billCycleDate).toISOString();
            case `${SCHEDULED_ORDER_OPTIONS.ON_SPECIFIED_DATE}`:
                return this.executionOptionsModel.specifiedDateFromPicker;
            default:
                return null;
        }
    }

    updateCreditCheckFinancing() {
        this.updateExecutionOptions(true);
    }

    updateExecutionOptions(isCreditCheckFinancingChange = false, isQuoteSkipped = false, isReasonChange = false) {
        this.executionOptionsModel.specifiedDate = this.getExecutionDate();

        this.fdoExecutionOptionsForm && this.fdoExecutionOptionsForm.SpecifiedDate && this.fdoExecutionOptionsForm.SpecifiedDate.$setValidity('invalidOrderScheduledForDate',
            !this.isFutureDatedOrderDateInvalid);

        if (this.isBillingEffectiveDateShown) {
            this.isBillingEffectiveDateInvalid = this.executionOptionsModel.billingEffectiveIntention &&
                convertStringToNumber(this.executionOptionsModel.billingEffectiveIntention) === BILLING_EFFECTIVE_DATE_ORDER_OPTIONS.ON_SPECIFIED_DATE &&
                !this.executionOptionsModel.billingEffectiveDate;

            this.fdoExecutionOptionsForm && this.fdoExecutionOptionsForm.BillingEffectiveDate && this.fdoExecutionOptionsForm.BillingEffectiveDate.$setValidity('invalidBillingEffectiveDate',
                !this.isBillingEffectiveDateInvalid);
        }

        if (this.executionOptionsModel.billingEffectiveIntention !== BILLING_EFFECTIVE_DATE_ORDER_OPTIONS.ON_SPECIFIED_DATE.toString()) {
            this.executionOptionsModel.billingEffectiveDate = null;
        }

        if (this.executionOptionsModel.effective !== BILLING_EFFECTIVE_DATE_ORDER_OPTIONS.ON_SPECIFIED_DATE.toString()) {
            this.executionOptionsModel.specifiedDate = null;
            this.executionOptionsModel.specifiedDateFromPicker = null;
        }

        if (this.executionOptionsModel.effective === SCHEDULED_ORDER_OPTIONS.ON_SPECIFIED_DATE.toString() && (!this.executionOptionsModel.specifiedDate || !this.executionOptionsModel.specifiedDateFromPicker)) {
            const defaultDate = this.executionOptionsSavedCopy && this.executionOptionsSavedCopy.specifiedDate || this.minDate;
            this.executionOptionsModel.specifiedDate = defaultDate;
            this.executionOptionsModel.specifiedDateFromPicker = defaultDate;
            if (this.isBillingEffectiveDateShown) {
                this.billingEffectiveMaximumDate = this.getBillingEffectiveMaxDate();
            }
        }

        this.onExecutionOptionChange()(this.executionOptionsModel, isCreditCheckFinancingChange, isReasonChange);
        this.doErrorsExistOnFdoForm()(this.fdoExecutionOptionsForm && this.fdoExecutionOptionsForm.$invalid);

        if (this.isBillingEffectiveDateShown &&
            this.recalculateQuote &&
            !isQuoteSkipped &&
            !this.isBillingEffectiveDateInvalid &&
            !this.isFutureDatedOrderDateInvalid) {
            this.recalculateQuote()();
        }
        this.onExecutionOptionChange()(this.executionOptionsModel);

        this.isFutureDatedOrderDateInvalid = this.executionOptionsModel.effective &&
            convertStringToNumber(this.executionOptionsModel.effective) === SCHEDULED_ORDER_OPTIONS.ON_SPECIFIED_DATE &&
            !this.executionOptionsModel.specifiedDate;
    }

    onSpecifiedDateChange(date) {
        if (this.isBillingEffectiveDateShown) {
            this.billingEffectiveMaximumDate = this.getBillingEffectiveMaxDate();
        }

        this.executionOptionsModel.effective = `${this.SCHEDULED_ORDER_OPTIONS.ON_SPECIFIED_DATE}`;
        this.executionOptionsModel.specifiedDateFromPicker = date || null;
        this.isBillingEffectiveDateShown ? this.filterAndFormatBillingEffectiveDateOptions() : this.updateExecutionOptions();
    }

    onBedDateChange(date) {
        this.executionOptionsModel.billingEffectiveIntention = `${BILLING_EFFECTIVE_DATE_ORDER_OPTIONS.ON_SPECIFIED_DATE}`;
        this.executionOptionsModel.billingEffectiveDate = date || null;
        this.updateExecutionOptions(false,
            !this.executionOptionsModel.billingEffectiveDate
        );
    }
}

export default {
    template: require('./future.dated.orders.html'),
    bindings: {
        billCycleDate: '<',
        billCycleToolTipContent: '<',
        billingEffectiveDateOptions: '<?',
        billingEffectiveDateSettings: '<?',
        disconnectAndRemoveOfferCustomerReasons: '<?',
        doErrorsExistOnFdoForm: '&',
        executionOptionsSavedCopy: '<',
        initializeDatesToNull: '<?',
        isBillingEffectiveDateShown: '<?',
        isFullDisconnect: '<?',
        isNewConnect: '<',
        isNotExpanded: '<?',
        maxDate: '<',
        minDate: '<',
        onExecutionOptionChange: '&',
        onFdoFormValidityChange: '&?',
        orderAllowedForToday: '<',
        recalculateQuote: '&?',
        showApplyBalanceToInvoice: '<?',
        showBillCycleOption: '<?',
        showBillCycleToolTip: '<?',
        showDatePickerOption: '<?',
        showImmediateOption: '<?'
    },
    controller: FutureDatedOrdersController
};
