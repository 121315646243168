import angular from 'angular';
import 'angular-hotkeys';
import 'invision-core';

import Remark from './dbssDashboard/remark/remark.module';
import RecentActivity from './dbssDashboard/recentActivity/recent.activity.module';
import CreateRemarkPopup from './dbssDashboard/createRemarkPopup/create.remark.popup.module';

import DashboardComponent from './dashboard.component';
import {routing as RoutingConfig} from './dashboard.config';

import AccountBalanceWidget from './dbssDashboard/accountBalance/account.balance.component';
import AccountStatusWidget from './ottDashboard/accountStatus/account.status.component';
import ActiveProductsWidget from './dbssDashboard/activeProducts/active.products.component';
import DeviceFinancingWidget from './dbssDashboard/deviceFinancing/device.financing.component';
import TreatmentWidget from './dbssDashboard/treatment/treatment.component';
import {
    AccountBalanceWidgetMountPoint,
    AccountStatusWidgetMountPoint,
    ActiveProductWidgetMountPoint,
    ActiveSubscriptionWidgetMountPoint,
    AutoRenewMountPoint,
    CancelEarlyOffCyclePaymentMountPoint,
    ConvertToCreditMountPoint,
    CustomWidgetMountPoint,
    DeviceFinancingWidgetMountPoint,
    OfferingsWidgetMountPoint,
    OpenInvoicesWidgetMountPoint,
    RecentProductsWidgetMountPoint,
    ServicesAndOfferingWidgetMountPoint,
    ServicesWidgetMountPoint,
    SharedUsageWidgetMountPoint,
    SuspendResumeMountPoint,
    TreatmentWidgetMountPoint
} from '@invision/customercare2';
import CustomWidget from './customWidget/custom.widget.component';
import CustomWidgetIframeDirective from './customWidget/custom.widget.iframe.directive';
import NextBestActionWidget from './nextBestAction/next.best.action.component';
import OfferingDeprecatedWidgetList from './dbssDashboard/offering/offering.list.deprecated.component';
import OfferingDeprecatedWidget from './dbssDashboard/offering/offering.deprecated.component';
import OfferingOption from './dbssDashboard/offering/offeringOption/offering.option.component';
import OpenInvoicesWidget from './dbssDashboard/openInvoices/open.invoices.component';
import ProductsWidget from './ottDashboard/products/products.component';
import ServicesAndOffersWidgetModule from './dbssDashboard/servicesAndOffers/services.and.offers.module';
import ServicesDeprecatedWidget from './dbssDashboard/services/services.deprecated.component';
import ServicesDeprecatedWidgetContents from './dbssDashboard/services/services.contents.deprecated.component';
import SubscriptionsWidget from './ottDashboard/subscriptions/subscriptions.component';
import UpdateServiceFriendlyNameModal from './dbssDashboard/services/update.friendly.name.modal.component';
import {react2angular} from 'react2angular';
import {InvoiceCards} from './dbssDashboard/openInvoices/invoiceCards/invoice.cards';

export default angular
    .module('invision.customercare.customer.dashboard', [
        'cfp.hotkeys',
        'invision.core.components',
        Remark,
        RecentActivity,
        CreateRemarkPopup,
        ServicesAndOffersWidgetModule
    ])
    .component('dashboard', DashboardComponent)
    .component('accountBalanceWidget', AccountBalanceWidget)
    .component('accountStatusWidget', AccountStatusWidget)
    .component('activeProductsWidget', ActiveProductsWidget)
    .component('customWidget', CustomWidget)
    .component('deviceFinancingWidget', DeviceFinancingWidget)
    .component('nextBestActionWidget', NextBestActionWidget)
    .component('offeringDeprecatedWidgetList', OfferingDeprecatedWidgetList)
    .component('offeringDeprecatedWidget', OfferingDeprecatedWidget)
    .component('offeringOption', OfferingOption)
    .component('openInvoicesWidget', OpenInvoicesWidget)
    .component('productsWidget', ProductsWidget)
    .component('servicesDeprecatedWidget', ServicesDeprecatedWidget)
    .component(
        'servicesDeprecatedWidgetContents',
        ServicesDeprecatedWidgetContents
    )
    .component('subscriptionsWidget', SubscriptionsWidget)
    .component('treatmentWidget', TreatmentWidget)
    .component('updateServiceFriendlyNameModal', UpdateServiceFriendlyNameModal)
    .component(
        'invoiceCards',
        react2angular(InvoiceCards, [
            'invoiceData',
            'convertToCredit',
            'disputeInvoice',
            'goToInvoiceDetails',
            'makeAdjustment',
            'makePayment'
        ])
    )
    .component(
        'suspendResume',
        react2angular(SuspendResumeMountPoint, [
            'formInstructions',
            'hideOnNextBillCycle',
            'isNetworkAndBillingOnly',
            'onClose',
            'onSubmit',
            'open',
            'orderTypeCode',
            'subscriberId',
            'title'
        ])
    )
    .component(
        'autoRenew',
        react2angular(AutoRenewMountPoint, [
            'action',
            'currencyCode',
            'entity',
            'isOpen',
            'offeringInstanceId',
            'onClose',
            'onSubmit',
            'pricingPlansInOffer',
            'productId',
            'subscriberId',
            'offerDetails',
            'planDetails'
        ])
    )
    .component(
        'convertToCreditForm',
        react2angular(ConvertToCreditMountPoint, [
            'customerId',
            'initialValues',
            'isOpen',
            'onSubmit',
            'onClose'
        ])
    ).component(
        'cancelEarlyOffCyclePayment',
        react2angular(CancelEarlyOffCyclePaymentMountPoint, [
            'isOpen',
            'onClose',
            'onSubmit',
            'subscriberId',
            'transactionId'
        ])
    )
    .component('reactOfferingsWidget', react2angular(OfferingsWidgetMountPoint, ['customerId', 'offeringId', 'offeringInstanceId', 'refreshOfferingDetails']))
    .component('reactServicesWidget', react2angular(ServicesWidgetMountPoint, ['customerId', 'serviceIdentifier']))
    .component('sharedUsageWidget', react2angular(SharedUsageWidgetMountPoint, ['customerId']))
    .component('reactAccountStatusWidget', react2angular(AccountStatusWidgetMountPoint, ['customerId']))
    .component('reactSubscriptionsWidget', react2angular(ActiveSubscriptionWidgetMountPoint, ['customerId']))
    .component('reactCustomWidget', react2angular(CustomWidgetMountPoint, ['customerId', 'widgetData']))
    .component('reactServicesAndOfferingWidget', react2angular(ServicesAndOfferingWidgetMountPoint, ['customerId']))
    .component('reactOpenInvoicesWidget', react2angular(OpenInvoicesWidgetMountPoint, ['customerId']))
    .component('reactActiveProductWidget', react2angular(ActiveProductWidgetMountPoint, ['customerId']))
    .component('reactDeviceFinancingWidget', react2angular(DeviceFinancingWidgetMountPoint, ['customerId']))
    .component('reactTreatmentWidget', react2angular(TreatmentWidgetMountPoint, ['customerId']))
    .component('reactAccountBalanceWidget', react2angular(AccountBalanceWidgetMountPoint, ['customerId']))
    .component('reactRecentProductsWidget', react2angular(RecentProductsWidgetMountPoint, ['customerId']))
    .config(RoutingConfig)
    .directive('customWidgetIframeDirective', CustomWidgetIframeDirective).name;
