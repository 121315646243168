import angular from 'angular';
import angularDynamicLocale from 'angular-dynamic-locale';
import angularRedux from 'ng-redux';
import angularSanitize from 'angular-sanitize';

import {faultCodeBehaviors} from 'invision-core/src/api/fault.code.behaviors';
import {warningCodeBehaviors} from 'invision-core/src/api/warning.code.behaviors';

import acsrCustomerSwitch from './components/acsrCustomerSwitch/acsrCustomerSwitch.module';
import addressSwitcherModule from './components/addressSwitcher/address.switcher.module';
import billingPaymentsModule from './components/billingPayments/billing.payments.module';
import couponModule from './components/couponRedemptionCode/coupon.redemption.code.module';
import createCustomerModule from './components/createCustomer/create.customer.module';
import customerModule from './components/customer/customer.module';
import editOfferModule from './components/customer/orders/edit/offer/edit.offer.wizard.module';
import giftCardModule from './components/giftCard/gift.card.module';
import mainModule from './components/main/main.module';
import newConnectModule from './components/wizards/newConnectWizard/new.connect.wizard.module';
import createProductsOrderModule from './components/customer/orders/create/productsOrder/create.products.order.wizard.module';
import recentCustomersModule from './components/recentCustomers/recent.customers.module';
import searchModule from './components/search/search.module';
import sharedModule from './components/shared/shared.module';
import paymentMethodSwitcher from './components/paymentMethodSwitcher/payment.method.switcher.module';
import welcomeModule from './components/welcome/welcome.module';

import apiFaultBehaviors from './api/fault.code.behavior';
import apiWarningBehaviors from './api/warning.code.behavior';
import {DateTimePicker} from '@invision/ui/src/inputs/DatePicker/DateTimePicker';
import {DatePicker} from '@invision/ui/src/inputs/DatePicker/DatePicker';
import {react2angular} from 'react2angular';

export default angular.module('invision.customercare', [
    acsrCustomerSwitch,
    angularDynamicLocale,
    angularRedux,
    angularSanitize,
    addressSwitcherModule,
    billingPaymentsModule,
    couponModule,
    createCustomerModule,
    customerModule,
    editOfferModule,
    giftCardModule,
    mainModule,
    newConnectModule,
    paymentMethodSwitcher,
    recentCustomersModule,
    createProductsOrderModule,
    searchModule,
    sharedModule,
    welcomeModule
]).component('reactDateTimePicker', react2angular(DateTimePicker, [
    'value',
    'onChange',
    'minDate',
    'maxDate',
    'disabled'
]))
    .component('reactDatePicker', react2angular(DatePicker, [
        'value',
        'onChange',
        'minDate',
        'maxDate',
        'disabled'
    ]))
    .run(() => {
        faultCodeBehaviors.register(apiFaultBehaviors);
        warningCodeBehaviors.register(apiWarningBehaviors);
    }).name;
