import {createSelector} from 'reselect';
import cloneDeep from 'lodash/cloneDeep';
import LocaleKeys from '../../../locales/keys';
import {
    AddressDataSelector,
    CurrentAddressesSelector
} from '../../../reducers/selectors/customer.addresses.selectors';
import {
    AddressHelper,
    i18n,
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';
import {
    addressStateRegionProvinceValueOptionsForCountry
} from '../../../reducers/helpers/customer.selectors.helpers';
import {GENERAL_STATUSES} from '../../shared/constants/general.status.constants';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {hasAdminAccess} from 'invision-core/src/components/security/permission.service';
import {ADDRESS_ACCESS} from '../../../security.attributes';

const NO_ADDRESSES = [];

export const MakePaymentStoreSelector = state => {
    return state.customercare.recoverableUiState.makePayment;
};

export const MakePaymentInstrumentSelector = createSelector(
    [MakePaymentStoreSelector],
    (makePaymentStore) => {
        return makePaymentStore.paymentInstrument;
    }
);

export const MakePaymentAddressesSelector = createSelector(
    [CurrentAddressesSelector, UserSecurityAttributesSelector],
    (addresses, userSecurityAttributes) => {
        let popupAddresses = [];
        const hasAddressFullAccess = hasAdminAccess(userSecurityAttributes, ADDRESS_ACCESS);

        if (!addresses) {
            addresses = NO_ADDRESSES;
        }
        const blankBillingAddress = {
            Id: 0,
            Country: null,
            State: null
        };
        if (hasAddressFullAccess) {
            popupAddresses = [blankBillingAddress].concat(cloneDeep(addresses));
        } else {
            popupAddresses = cloneDeep(addresses);
        }
        return popupAddresses.filter((address) => {
            return address.Status !== GENERAL_STATUSES.REMOVED;
        }).map((address) => {
            address.dropDownString = address.Id === 0 ? i18n.translate(LocaleKeys.ADD_NEW) : AddressHelper.createBillingAddressString(address);
            return address;
        });
    }
);

export const MakePaymentAddressSelector = createSelector(
    [MakePaymentAddressesSelector, MakePaymentInstrumentSelector],
    (makePaymentAddresses, makePaymentInstrument) => {
        if (!makePaymentInstrument || !makePaymentInstrument.BillingAddress || !makePaymentAddresses.length) {
            return;
        }
        return makePaymentAddresses.find((address) => {
            return address.Id === makePaymentInstrument.BillingAddress.Id;
        });
    }
);

export const EditAddressPopupStateRegionProvinceValueOptions = createSelector([
    AddressDataSelector,
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressCountry),
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressStateProvinceRegion)
], (addressData, countryCodes, stateCodes) => {
    if (!addressData || !addressData.address) {
        return null;
    }
    return addressStateRegionProvinceValueOptionsForCountry(addressData.address.Country, countryCodes, stateCodes);
});
