import {
    emptyTransactionsList,
    removeTransactionsFilter
} from '../../../reducers/actions/customer.transactions.actions';

export const STATE_OR_NAME = 'index.customercare.customer.transactions';
export const LIST_STATE_OR_NAME = 'index.customercare.customer.transactions.list';
export const TRANSACTION_DETAILS_STATE = `${STATE_OR_NAME}.details`;

export default ($stateProvider) => {
    $stateProvider.state(STATE_OR_NAME, {
        url: '/transactions',
        redirectTo: `${STATE_OR_NAME}.list`,
        template: '<ui-view class="u-flexKeepHeight"></ui-view>',
        params: {
            previousRoute: null,
            subscription: null,
            selectedPaymentId: null
        },
        onExit: ($ngRedux) => {
            $ngRedux.dispatch(removeTransactionsFilter());
            $ngRedux.dispatch(emptyTransactionsList());
        }
    }).state(`${STATE_OR_NAME}.list`, {
        url: '/list?{paymentInstrumentId}{setPaymentFailureFilter}{lockerItemId}{productName}',
        params: {
            fromTransactionSearch: false,
            paymentInstrumentId: null
        },
        template: '<transactions-list></transactions-list>'
    }).state(TRANSACTION_DETAILS_STATE, {
        url: '/:transactionId?{orderId}',
        template: '<transaction-details></transaction-details>'
    });
};
